import { IResponse } from "../types";
import axios from "./";

const TagService = {
  getDefaultTags: async () => (await axios.get("tag")).data,

  updateTags: async (newTags: any[], response_id: string) =>
    (await axios.put(`tag/${response_id}`, newTags)).data,

  saveTags: async (newTags: string[], response_id: IResponse["id"]) =>
    (await axios.post(`tag/${response_id}`, newTags)).data,
};

export default TagService;
