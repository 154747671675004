import styled from "styled-components";

import { space, margin } from "styled-system";
import { Props } from "./types";

interface SelectProps {
  itemHasSelected: boolean;
  isOpen: boolean;
  hasError?: boolean;
}

export const StyledBoxSelect = styled.div<Props>`
  width: 100%;
  ${space};
  ${margin};
`;

export const StyledErrorMessage = styled.label`
  width: 100%;
  color: "#D50000";
  text-align: right;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
`;

export const StyledSelect = styled.div<SelectProps>`
  font-family: "Inter", sans-serif;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  height: 56px;
  width: 100%;
  background: "#FFFFFF";
  position: relative;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.hasError ? "#D50000" : props.isOpen ? "#000000" : "#D5D5DC"};
  padding-left: 16px;
  transition: all 300ms;
`;

export const StyledPlaceHolder = styled.div<{ itemSelected: boolean }>`
  padding-top: ${(props) => (props.itemSelected ? "8px" : "16px")};
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  display: flex;
  width: calc(100% - 16px);
`;

export const StyledDropdown = styled.div<{ isOpen: boolean }>`
  top: 60px;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  overflow-y: auto;
  position: absolute;
  border-radius: 4px;
  transition: all 300ms;
  flex-direction: column;
  max-height: ${(props) => (props.isOpen ? 180 : 0)}px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: "#000000"; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
`;

export const StyledItem = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  flex-direction: row;
  display: flex;
  padding: 16px;
  transition: all 300ms;
  border-bottom: 1px solid #d5d5dc;
  color: ${(props) => (props.isSelected ? "#FFFFFF" : "#000000")};
  background-color: ${(props) => (props.isSelected ? "#000000" : "#FFFFFF")};

  &:hover {
    background-color: #eee;
  }
`;
