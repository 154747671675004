import { IAttachment, IResponse } from "../types";
import axios from "./";

const AttachmentService = {
  saveAttachment: async (response_id: IResponse["id"], img: any) =>
    (
      await axios.post(`attachment/${response_id}`, img, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data,

  delete: async (attachments_id: IAttachment["id"]) =>
    (await axios.delete(`attachment/${attachments_id}`)).data,
};

export default AttachmentService;
